import format from '../components/format.module.css';
import Header from '../components/header';
import Footer from '../components/footer';
import Script from 'next/script';

export default function Layout
({children}) {

    return (<>
    <Script>{`  var _paq = window._paq = window._paq || [];
  /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
  _paq.push(['trackPageView']);
  _paq.push(['enableLinkTracking']);
  (function() {
    let u="//www.shoppilot.de/anly/matomo/";
    _paq.push(['setTrackerUrl', u+'matomo.php']);
    _paq.push(['setSiteId', '2']);
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src=u+'matomo.js'; s.parentNode.insertBefore(g,s);
  })();`}

    </Script>
         <Header></Header>
         <main>{children}</main>
        <Footer></Footer>
    </>);
}
